import styled from '@emotion/styled'
import { Row as DefaultRow, Col as DefaultCol } from '../UI/Grid'
import { Button as DefaultButton } from '../UI/Buttons'

export const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 16px;
  width: 100%;
  flex-direction: column;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 48px 16px;
  }
`

export const InnerWrapper = styled.div`
  padding-top: 32px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  max-width: 400px;
  padding-bottom: 180px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 16px;
  }
`

export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.4;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

export const Text = styled.div`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2rem;
  text-align: center;
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: -72px;
  max-width: 400px;
  max-height: 427px;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}) {
    display: none;
  }
`

export const Bubbles = styled.img`
  position: relative;
  max-width: 156px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-left: 16px;
    max-width: 100px;
  }
`

export const Row = styled(DefaultRow)``
export const Col = styled(DefaultCol)``

export const Button = styled(DefaultButton)`
  max-width: 200px;
  margin-top: 24px;
  justify-content: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 100%;
  }
`
