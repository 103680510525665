import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../components/SEO'
import Success from '../components/SuccessPage'

const SuccessPage = ({ pageContext: { locale }, location }) => (
  <>
    <SEO pathname={location.pathname} locale={locale} />
    <Success />
  </>
)

SuccessPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
export default SuccessPage
